<template>
	<!-- v-loading="loading" -->
	<div style="background: #fff;">
		<div class="SystemNewsbox" style="background: #fff !important;padding: 15px;">
			<el-tabs v-model="listState" @tab-click="filterFun">
				<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id + ''"></el-tab-pane>
			</el-tabs>
			<div class="newsBox" v-for="(item, index) in noticeList" :key="index">
				<div v-if="item.DemandType==1" class="linkCommon displayCommon" style="width: 100%;">
					<el-link :underline="false" class="displayCommon" style="width:90%" target="_blank" :href="item.Href">
						<span>[{{ item.DemandTypeString }}]</span>
						<span class="overflowCommon" style="padding-left: 10px;">{{ item.Message }}</span>
					</el-link>
				</div>
				<div v-else class="linkCommon displayCommon" style="width: 100%;" @click="itemNews(item)">
					<el-link :underline="false" class="displayCommon" style="width:90%">
						<span>[{{ item.DemandTypeString }}]</span>
						<span class="overflowCommon" style="padding-left: 10px;">{{item.StateString}}-{{ item.Message }}</span>
					</el-link>
				</div>
			</div>
			<el-pagination
				v-if="Total"
				style="margin:20px 0;float:right;"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-sizes="[10, 20, 30, 40, 50]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="Total"
			></el-pagination>
		</div>
		<div style="height: 100px;background-color: #fff;"></div>
	</div>
</template>

<script>
import {} from '@/api/TurnTomySelf';
import config from '@/config/index';
import { systemNoticeList } from '@/api/TurnTomySelf';
export default {
	data() {
		return {
			loading: false,
			listStateList: [
				{
					id: 0,
					value: '系统更新'
				},
				{
					id: 1,
					value: '需求反馈'
				}
			],
			listState: '0',
			Total: 0,
			currentPage: 1,
			pageSize: 20,
			noticeList: [],
			currentTab: 0
		};
	},
	beforeMount() {},
	created() {
		this.getSystemNoticeList();
	},
	methods: {
		async getList() {
			try {
				this.loading = true;
			} catch (err) {
			} finally {
				this.loading = false;
			}
		},
		itemNews(item) {
			this.$router.push({
				path: '/needDetail',
				query: {
					Id: item.Id
				}
			});
		},
		async getSystemNoticeList() {
			try {
				this.loading = true;
				let data = {
					DemandType: this.currentTab == 0 ? 1 : 0,
					Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
					Take: this.pageSize // 取的数据
				};
				let result = await systemNoticeList(data);
				if (result.IsSuccess) {
					this.Total = result.Result.Total;
					this.noticeList = result.Result.Results || [];
				}
			} catch (err) {
			} finally {
				this.loading = false;
			}
		},
		//顶部tab切换事件
		filterFun(tab, event) {
			if (tab.index == '0') {
				this.currentTab = 0;
			} else {
				this.currentTab = 1;
			}
			this.currentPage = 1;
			this.getSystemNoticeList();
		},
		// 切换显示条数
		handleSizeChange(val) {
			// console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getSystemNoticeList();
		},

		// 翻页
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.currentPage = val;
			this.getSystemNoticeList();
		}
	}
};
</script>

<style lang="less" scoped>
.displayCommon {
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}

.newsBox {
	border: 1px solid #f5f5f5;

	.linkCommon {
		padding: 15px;
		box-sizing: border-box;

		border-bottom: 1px solid #f5f5f5;

		::v-deep .el-link--inner {
			display: flex;
			align-items: center;
			width: 100%;
		}

		.overflowCommon {
			width: 93%;
			overflow: hidden;
			text-overflow: ellipsis;
			-o-text-overflow: ellipsis;
			-webkit-text-overflow: ellipsis;
			-moz-text-overflow: ellipsis;
			white-space: nowrap;
		}

		&:nth-last-child {
			border-bottom: none;
		}
	}
}
</style>
